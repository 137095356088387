<template>
    <layout-full-width :title="$tc('service-provider', 2)">
        <component-actions :action-filters="[]"
                           :bulk="bulk"
                           :itemsPerPage="serviceProviders.itemsPerPage"
                           :page="serviceProviders.page"
                           :primary-actions="[]"
                           :total="serviceProviders.total"
                           @applyFilters="onApplyFilters"
                           @changeItemsPerPage="onChangeItemsPerPage"
                           @paginate="onChangePage"
                           @search="onSearch"/>

        <div class="row">
            <div class="col-12">
                <div class="table-responsive">
                    <table :class="{'table-hover': !loading.listServiceProviders}" class="table">
                        <thead>
                        <tr>
                            <th scope="col">
                                <button class="btn btn-checkbox" @click="toggleCheckbox('ALL')">
                                    <font-awesome-icon
                                        :class="getSelectAllStatus() ? 'text-primary' : ''"
                                        :icon="getSelectAllStatus() ? ['fas', 'check-square'] : ['far', 'square']"/>
                                </button>
                            </th>
                            <th scope="col">{{ $tc('name', 1) }}</th>
                            <th scope="col">{{ $tc('status', 1) }}</th>
                            <th scope="col"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-cloak v-if="!loading.listServiceProviders && serviceProviders.list.length > 0">
                            <tr v-for="(serviceProvider, i) in serviceProviders.list" :key="'service-provider-' + i">
                                <td>
                                    <button class="btn btn-checkbox" @click="toggleCheckbox(i)">
                                        <font-awesome-icon
                                            :class="serviceProvider.selected ? 'text-primary' : ''"
                                            :icon="serviceProvider.selected ? ['fas', 'check-square'] : ['far', 'square']"/>
                                    </button>
                                </td>
                                <td>{{ serviceProvider.serviceProviderDescLong }}</td>
                                <td>
                                    <span class="badge bg-primary">{{ $t('active') }}</span>
                                </td>
                                <td></td>
                            </tr>
                        </template>
                        <template v-cloak v-if="!loading.listServiceProviders && serviceProviders.list.length === 0">
                            <tr>
                                <td colspan="99">
                                    <div
                                        class="py-3 text-primary d-flex align-items-center justify-content-center flex-column mb-0"
                                        role="alert">
                                        <font-awesome-icon icon="exclamation-triangle" size="3x"/>
                                        <div class="mt-2 fs-4">
                                            {{ $t('pages.service-providers.no-service-providers-found') }}
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </template>
                        <template v-if="loading.listServiceProviders">
                            <tr>
                                <td colspan="99">
                                    <component-spinner class="py-4" color="text-primary" type="spinner-grow"/>
                                </td>
                            </tr>
                        </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </layout-full-width>
</template>

<script>
import LayoutFullWidth from "@/layouts/LayoutFullWidth";
import ComponentActions from "@/components/ui/ComponentActions";
import ComponentSpinner from "@/components/ui/ComponentSpinner";

export default {
    name: "PageServiceProviders",
    components: {ComponentSpinner, ComponentActions, LayoutFullWidth},
    computed: {
        serviceProviders() {
            return this.$store.state.SERVICE_PROVIDER_SERVICE.serviceProviders;
        }
    },
    data() {
        return {
            loading: {
                listServiceProviders: true
            },
            bulk: false
        }
    },
    methods: {
        async listServiceProviders() {
            this.loading.listServiceProviders = true;
            await this.$store.dispatch('SERVICE_PROVIDER_SERVICE/LIST', {
                filter: {},
                order: {meta_insert_dt: 'DESC'},
                page: 1,
                itemsPerPage: 25
            });
            this.loading.listServiceProviders = false;
        },
        onChangePage() {
            console.log('onChangePage');
        },
        onSearch() {
            console.log('onSearch');
        },
        onChangeItemsPerPage() {
            console.log('onChangeItemsPerPage');
        },
        onApplyFilters() {
            console.log('onApplyFilters');
        },
        async toggleCheckbox(i) {
            if (i === 'ALL') {
                if (this.getSelectAllStatus()) {
                    this.serviceProviders.list.forEach(serviceProvider => {
                        serviceProvider.selected = false;
                    })
                } else {
                    this.serviceProviders.list.forEach(serviceProvider => {
                        serviceProvider.selected = true;
                    })
                }
            } else {
                this.serviceProviders.list[i].selected = !this.serviceProviders.list[i].selected;
            }

            this.$forceUpdate();
            this.getActionButton();
        },
        getSelectAllStatus() {
            const serviceProviderCount = this.serviceProviders.list.length;
            let selectedServiceProviderCount = 0;
            this.serviceProviders.list.forEach(serviceProvider => {
                if (serviceProvider.selected) {
                    selectedServiceProviderCount += 1;
                }
            })
            return serviceProviderCount === selectedServiceProviderCount && serviceProviderCount !== 0;
        },
        getActionButton() {
            let selectedServiceProviderCount = 0;
            this.serviceProviders.list.forEach(serviceProvider => {
                if (serviceProvider.selected) {
                    selectedServiceProviderCount += 1;
                }
            })

            this.bulk = selectedServiceProviderCount !== 0;
        }
    },
    beforeMount() {
        this.listServiceProviders();
    }
}
</script>

<style lang="scss" scoped>
</style>
